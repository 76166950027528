import React from 'react';
import styled from "styled-components";
import ArrowIconSrc from "../../../asset/icon__arrow-shop.svg";
import CroppedSockSrc from "../../../asset/mock/main/img-socks.svg";

const MainShopItem = ({
                          title,
                          titleStyle,
                          showSubTitle,
                          subTitle,
                          subTitleStyle,
                          width,
                          height,
                          thumbnailWidth,
                          thumbnailSrc,
                          color,
                          onClick,
                          showArrow = false
                      }) => {
    return (
        <ShopItemWrapper width={width} height={height} onClick={onClick}>
            <Thumbnail src={thumbnailSrc} width={thumbnailWidth} height={height}/>
            <CategoryPostContent src={CroppedSockSrc} thumbnailWidth={thumbnailWidth} width={width}
                                 height={height} color={color}>
                <Title {...titleStyle}>{title}</Title>
                {showSubTitle && <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <SubTitle {...subTitleStyle}>{subTitle}</SubTitle>
                    {showArrow && <div><img src={ArrowIconSrc} width="440px" height="38px"/></div>}
                </div>}
            </CategoryPostContent>
        </ShopItemWrapper>
    );
};

export default MainShopItem;

MainShopItem.defaultProps = {
    type: 'right',
    showSubTitle: true,
    width: "100%",
    height: "564px",
    thumbnailWidth: "50%",
    titleStyle: {
        color: "#000000",
        size: "55px",
        weight: 700,
    },
    subTitleStyle: {
        color: "#000000",
        size: "20px",
        weight: 500,
    },
}

const ShopItemWrapper = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;

const CategoryPostContent = styled.div`
  width: 100%;
  height: ${({height}) => height && height};
  word-break: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
  box-sizing: border-box;
  ${({color}) => color && `background-color: ${color}`};
  ${({src}) => src && `background-image: url(${src})`};
  background-size: 30.7% 100%;
  -webkit-background-size: 30.7% 100%;
  -moz-background-size: 30.7% 100%;
  -o-background-size: 30.7% 100%;
  background-repeat: no-repeat;
  background-position: right center;
  object-fit: contain;
`;

const Title = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  line-height: 57.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-weight: ${({weight}) => weight && weight};
`;

const SubTitle = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: normal;
`;

const Thumbnail = styled.img`
  width: ${({width}) => width && width};
  min-width: ${({width}) => width && width};
  min-height: ${({height}) => height && height};
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  object-fit: cover;
`;
