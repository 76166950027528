import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import LogoImg from "../../../asset/img__logo-black.png";

import Drawer from 'react-modern-drawer';
import Basket, {BasketContent} from "../../component/Basket";
import 'react-modern-drawer/dist/index.css'
import Search, {SearchTab} from "../../component/Search";
import {useRecoilState} from "recoil";
import {basketState, menuState, subMenuState} from "../../../recoil/atom";
import {useNavigate} from "react-router";

import {ShopContext} from "../../../context/ShopProvider";
import {useLocation} from "react-router-dom";

export const GNB_TYPE = {
    DEFAULT: 'default',
    GAUGE: 'gauge',
}

const Gnb = () => {
    const [type, setType] = useState(GNB_TYPE.DEFAULT);
    const {pathname} = useLocation();
    const {drawerOpen, openDrawer, closeDrawer, checkout, removeLineItem} = useContext(ShopContext);

    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);
    const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(subMenuState);
    const [containerHeight, setContainerHeight] = useState('80px');

    const [searchTabOpen, setSearchTabOpen] = useState(false);

    const [basketItem] = useRecoilState(basketState);

    const handleDrawerClose = () => {
        closeDrawer((prev) => !prev);
    }

    useEffect(() => {
        if (pathname.includes('magazine')) {
            if (pathname.split("/").length === 3 && !pathname.includes('fashion') && !pathname.includes('art')) {
                setType(GNB_TYPE.GAUGE);
                setContainerHeight('80px');
            } else {
                setType(GNB_TYPE.DEFAULT);
                setContainerHeight('110px');
            }
        } else {
            setType(GNB_TYPE.DEFAULT);
            setContainerHeight('80px');
        }
    }, [pathname])

    return (
        <Container>
            <MenuContainer type={type}
                           height={containerHeight}>
                <MenuRootWrapper>
                    <MenuWrapper>
                        <Menu weight={'500'} selected={selectedMenu?.includes('magazine')}
                              onClick={() => {
                                  setSelectedMenu('magazine');
                                  setSelectedSubMenu('all');
                                  navigate("/magazine");
                              }}>
                            MAGAZINE
                        </Menu>
                        <Menu weight={'500'} selected={selectedMenu?.includes('shop')}
                              onClick={() => {
                                  setSelectedMenu('shop');
                                  // setSelectedSubMenu('3d');
                                  navigate("/shop");
                              }}>
                            {/*onClick={() => window.location.replace("https://offshootdot.myshopify.com")}>*/}
                            SHOP
                        </Menu>
                        <Menu weight={'500'} selected={selectedMenu?.includes('pistol')}
                              onClick={() => {
                                  setSelectedMenu('pistol');
                                  setSelectedSubMenu('all');
                                  navigate("/pistol");
                              }}>
                            PISTOL
                        </Menu>
                    </MenuWrapper>
                    {selectedMenu?.includes('magazine') && type !== GNB_TYPE.GAUGE &&
                    <MenuWrapper>
                        <Menu weight={'500'} selected={selectedSubMenu?.includes('all')}
                              onClick={() => {
                                  setSelectedSubMenu('all');
                                  navigate("magazine");
                              }}>
                            Index
                        </Menu>
                        <Menu weight={'500'} selected={selectedMenu?.includes('art')}
                              onClick={() => {
                                  setSelectedSubMenu('art');
                                  navigate("magazine/art");
                              }}>
                            Art
                        </Menu>
                        <Menu weight={'500'} selected={selectedMenu?.includes('fashion')}
                              onClick={() => {
                                  setSelectedSubMenu('art');
                                  navigate("magazine/fashion");
                              }}>
                            Fashion
                        </Menu>
                    </MenuWrapper>}
                    {/*{selectedMenu?.includes('shop') && type !== GNB_TYPE.GAUGE &&*/}
                    {/*<MenuWrapper>*/}
                    {/*    <Menu weight={'600'} selected={selectedMenu?.includes('2d')}*/}
                    {/*          onClick={() => {*/}
                    {/*              setSelectedSubMenu('2d');*/}
                    {/*              navigate("shop/2d");*/}
                    {/*          }}>*/}
                    {/*        2D*/}
                    {/*    </Menu>*/}
                    {/*    <Menu weight={'600'} selected={selectedMenu?.includes('3d')}*/}
                    {/*          onClick={() => {*/}
                    {/*              setSelectedSubMenu('3d');*/}
                    {/*              navigate("shop/3d");*/}
                    {/*          }}>*/}
                    {/*        3D*/}
                    {/*    </Menu>*/}
                    {/*    <Menu weight={'600'} selected={selectedMenu?.includes('4d')}*/}
                    {/*          onClick={() => {*/}
                    {/*              setSelectedSubMenu('4d');*/}
                    {/*              navigate("shop/4d");*/}
                    {/*          }}>*/}
                    {/*        4D*/}
                    {/*    </Menu>*/}
                    {/*    <Menu weight={'600'} selected={selectedMenu?.includes('xd')}*/}
                    {/*          onClick={() => {*/}
                    {/*              setSelectedSubMenu('xd');*/}
                    {/*              navigate("shop/xd");*/}
                    {/*          }}>*/}
                    {/*        XD*/}
                    {/*    </Menu>*/}
                    {/*</MenuWrapper>}*/}
                </MenuRootWrapper>
                <LogoWrapper onClick={() => {
                    setContainerHeight('80px');
                    setSelectedMenu(null);
                    navigate("/");
                }}>
                    <img src={LogoImg}/>
                </LogoWrapper>
                <UtilityWrapper>
                    <Search onClick={() => setSearchTabOpen(true)}/>
                    <Basket itemCount={checkout?.lineItems?.length} onClick={() => {
                        openDrawer();
                    }}/>
                </UtilityWrapper>
            </MenuContainer>
            <Drawer
                size={450}
                open={drawerOpen}
                onClose={handleDrawerClose}
                direction='right'
                className='drawer__basket'
            >
                <BasketContent onClose={handleDrawerClose}/>
            </Drawer>
            <SearchTab open={searchTabOpen} onClose={() => setSearchTabOpen(false)}/>
        </Container>
    )
};

export default Gnb;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
const MenuContainer = styled.div`
  display: flex;
  padding: 30px 60px;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-width: calc(max(750px, 100vw));
  height: ${({height}) => height ? height : '80px'};
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;

  z-index: 5;
  position: fixed;
  ${({type}) => type === GNB_TYPE.DEFAULT ? 'background-color:#000000' : 'background-color:none'};
`;

const MenuRootWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const MenuWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: fit-content;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 36px;
  font-size: ${({size}) => size ? size : '15px'};
  font-weight: ${({weight}) => weight ? weight : 500};
  line-height: 18px;

  &:hover {
    cursor: pointer;
  }

  ${({selected}) => selected ? 'color: #C02126' : 'color:#ffffff'};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 190px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
`;

const UtilityWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
`;
