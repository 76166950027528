import React, {useState} from 'react';
import styled from "styled-components";
import MainVideoSrc from "../../asset/offshoot.mp4"
import MainTeaserSrc from "../../asset/offshootdot-main.mp4"
import {useNavigate} from "react-router";
import BannerImgSrc from "../../asset/img__banner.png"
import Banner from "../component/Banner";
import CategoryPostItem from "../component/CategoryPostItem";
import {mainPosts} from "../../mockData";
import Footer from "./layout/Footer";
import Modal from "react-modal";
import {useRecoilState} from "recoil";
import {menuState, showModalState} from "../../recoil/atom";
import CloseIconSrc from "../../asset/icon__close.png";
import RedCloseIconSrc from "../../asset/icon__close-big.svg";
import {newsLetterApi} from "../../api/newsLetter/NewsLetterApi";
import MagazineCoverImg from "../../asset/mock/main/magazine-cover.png";
import GoodImg from "../../asset/mock/main/lookbook-23fw-collection.png";
import HolidayImgSrc from "../../asset/mock/main/lookbook-holiday-collection.png";
import BowImgSrc from "../../asset/bow.gif";
import ArrowIconSrc from "../../asset/icon__arrow-white.svg";
import RedArrowIconSrc from "../../asset/icon__arrow-red.svg";
import MainShopItem from "../component/main/MainShopItem";
import MainShopCollection from "../component/main/MainShopCollection";

const MainPage = () => {
    const [showModal, setShowModal] = useRecoilState(showModalState);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Check your email again.');
    const [value, setValue] = useState('');

    const [, setSelectedMenu] = useRecoilState(menuState);

    const navigate = useNavigate();
    const handlePostClick = (id) => {
        navigate(`/magazine/${id}`);
    }

    const images = [{id: 0, image: BannerImgSrc}, {id: 1, image: BannerImgSrc}];

    const handleClickSubscribeButton = async () => {
        if (value?.split("@").length === 2) {
            const id = value.split("@")[0];
            const domain = value.split("@")[1];
            try {
                await newsLetterApi.subscribe({id, domain});
                setModalGuideMsg('Subscription completed.');
                setModalOpen(true);
                setShowModal(false);
                setTimeout(() => setModalOpen(false), 1000);
                setValue("");
            } catch (e) {
                console.log(e)
            }
        } else {
            setModalGuideMsg('Please check the input value');
            setModalOpen(true);
            setTimeout(() => setModalOpen(false), 1000);
        }
    };
    console.log("main page")
    return (
        <Container>
            <Background>
                <video className={'background-video'} autoPlay muted playsInline loop preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>
            <Filter/>
            <ContentsContainer>
                <Spacing top="30px"/>
                <ContentsWrapper>
                    <VideoWrapper>
                        <video style={{height: "100%", width: "100%", objectFit: "cover"}} autoPlay
                               muted playsInline loop preload="auto">
                            <source src={MainTeaserSrc} type="video/mp4"/>
                        </video>
                    </VideoWrapper>
                    <ImageWrapper onClick={() => {
                        window.open("https://shop.offshootdot.com");
                    }}>
                        <FlexBoxRow style={{alignItems: "flex-start"}}>
                            <div style={{
                                color: '#fff',
                                fontSize: '48px',
                                fontWeight: '500',
                                padding: '15px 20px',
                            }}>VISIT SHOP</div>
                            <div style={{
                                padding: '18px 36px 0 0',
                            }}><img src={ArrowIconSrc} width="127px" height="137px"/></div>
                        </FlexBoxRow>
                        <div>
                            <div style={{
                                position: "absolute",
                                zIndex: "2",
                            }}>
                                <img style={{padding: '15px 20px'}} src={RedArrowIconSrc} width="127px" height="137px"/>
                                <img style={{padding: '15px 20px'}} src={RedArrowIconSrc} width="127px" height="137px"/></div>
                            <img style={{position: "relative"}}src={MagazineCoverImg} width="360px" height="500px"/>
                        </div>
                    </ImageWrapper>
                </ContentsWrapper>
                <BannerWrapper>
                    <Banner images={images} speed={180000}/>
                </BannerWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={mainPosts[2].title}
                        titleStyle={{color: "#fff", size: "48px", weight: "500"}}
                        subTitle={mainPosts[2].subTitle}
                        subTitleStyle={{color: "#fff", size: "30px", weight: "400"}}
                        width="100%"
                        thumbnailWidth="25vw"
                        height="405px"
                        thumbnailSrc={mainPosts[2].categoryThumbnail} color={mainPosts[2].color}
                        showSubTitle
                        onClick={() => handlePostClick(mainPosts[2].id)}
                    />
                </ContentsWrapper>
                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={mainPosts[0].title}
                        titleStyle={{color: "#fff", size: "48px", weight: "500"}}
                        subTitle={mainPosts[0].subTitle}
                        subTitleStyle={{color: "#fff", size: "30px", weight: "400"}}
                        width="100%"
                        thumbnailWidth="25vw"
                        height="405px"
                        thumbnailSrc={mainPosts[0].categoryThumbnail} color={mainPosts[0].color}
                        showSubTitle
                        onClick={() => handlePostClick(mainPosts[0].id)}
                    />
                </ContentsWrapper>
                <ContentsWrapper>
                    <CategoryPostItem
                        type="left"
                        title={mainPosts[1].title}
                        titleStyle={{color: "#000", size: "48px", weight: "500"}}
                        subTitle={mainPosts[1].subTitle}
                        subTitleStyle={{color: "#000", size: "30px", weight: "400"}}
                        width="100%"
                        thumbnailWidth="25vw"
                        height="405px"
                        thumbnailSrc={mainPosts[1].categoryThumbnail} color={mainPosts[1].color}
                        showSubTitle
                        onClick={() => handlePostClick(mainPosts[1].id)}
                    />
                </ContentsWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <MainShopItem
                        showArrow={true}
                        title={"MONSTER SOCKS"}
                        titleStyle={{color: "#000", size: "48px", weight: "500"}}
                        subTitle={"DISCOVER MORE DETAILS"}
                        subTitleStyle={{color: "#000", size: "30px", weight: "400"}}
                        width="100%"
                        thumbnailWidth="25vw"
                        height="500px"
                        thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/monster_socks.png"}
                        color={"#FB5BC1"}
                        showSubTitle
                        onClick={() => window.open("https://shop.offshootdot.com/collections/socks")}
                    />
                </ContentsWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <MainShopCollection width="100%" height="fit-content" img={GoodImg}
                                        onClick={() => navigate(`shop/collection/23-fw-taboo`)}
                                        name="OFFSHOOT 2023 TABOO COLLECTION"/>
                </ContentsWrapper>
                <Spacing top="30px"/>

                <ContentsWrapper>
                    <HolidayImg src={HolidayImgSrc} onClick={() => navigate(`shop/collection/holiday`)}/>
                </ContentsWrapper>
                <Spacing top="30px"/>
                <Footer/>
            </ContentsContainer>
            <Modal style={{
                content: {
                    width: "400px",
                    height: "80vh",
                    border: "solid",
                    borderRadius: "1",
                    backgroundColor: "#C02126",
                    color: "#C02126",
                    top: '53%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                overlay: {
                    display: 'contents',
                    zIndex: 5,
                    top: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                },
            }}
                   isOpen={showModal}>
                <NewsLetterModalContent>
                    <FlexBoxColumn style={{justifyContent: "center"}}>
                        <FlexBoxRow>
                            <FlexBoxRow style={{
                                lineHeight: '34px',
                                fontSize: '48px',
                                fontWeight: '500'
                            }}>NEWSLETTER</FlexBoxRow>
                        </FlexBoxRow>
                        <Spacing top="20px"/>
                        <FlexBoxRow style={{fontSize: '30px', fontWeight: '500'}}>
                            Sign up for monthly<br/>dispatches on fashion<br/>and fine art from the<br/>OFFSHOOT
                        </FlexBoxRow>
                        <Spacing top="80px"/>
                        <FlexBoxColumn>
                            <EmailInput value={value} onChange={(e) => setValue(e.target.value)}
                                        placeholder="Your Email"/>
                            <Spacing top="10px"/>
                            <SubscribeButton onClick={handleClickSubscribeButton}>Subscribe</SubscribeButton>
                        </FlexBoxColumn>
                    </FlexBoxColumn>
                    <FlexBoxColumn>
                        <BigCloseButton src={RedCloseIconSrc} width="95px" onClick={() => setShowModal(false)}/>
                    </FlexBoxColumn>
                </NewsLetterModalContent>
            </Modal>
            <Modal style={{
                content: {
                    width: "400px",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                overlay: {
                    // display: 'none'
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row'}}>
                    {modalGuideMsg}
                    <CloseButton src={CloseIconSrc} width="20px" height="20px" onClick={() => setModalOpen(false)}/>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default MainPage;

const HolidayImg = styled.img`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;

  &:hover {
    cursor: pointer;
  }
`;
const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;
const ContentsContainer = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
`;

export const Spacing = styled.div`
  padding-top: ${({top}) => top || '0'};
`;

const Background = styled.div`
  width: 100vw;
  display: flex;
  position: fixed;
  top: 80px;
  left: 0;

  .background-video {
    width: 100vw;
    height: 100vh;
    object-fit: fill;
    z-index: -2;
  }

  //background-blend-mode: darken;
`;
const Filter = styled.div`
  position: fixed;
  top: 80px;
  //width: calc(max(100vw, 1630px));
  min-width: 100vw;
  min-height: 100vh;
  z-index: -2;
  background-color: white;
  mix-blend-mode: difference;
`;

const ContentsWrapper = styled.div`
  display: flex;
  //width: 1450px;
  width: 80vw;
  min-width: calc(max(1354px, 80vw));
  justify-content: center;
  align-items: center;
  height: fit-content;
`;

const VideoWrapper = styled.div`
  width: 25vw;
  min-width: calc(max(480px, 25vw));
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: calc(max(874px, 55vw));
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  background: #C02126;

  &:hover {
    cursor: pointer;
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  //width: 1450px;
  width: 80vw;
  min-width: calc(max(1354px, 80vw));
`;

const NewsLetterModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 38px 50px;
  background-color: #fff;
  background-image: url(${BowImgSrc});
  background-size: cover;
  background-position: left -150px top 40px;
  background-repeat: no-repeat;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px
`;

const ModalImageWrapper = styled.div`
  //width: calc(1450px / 2);
  width: 100%;
  height: 292px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const FlexBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FlexBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const BigCloseButton = styled.img`
  display: flex;
  width: 95px;
  justify-content: flex-end;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

const CloseButton = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const EmailInput = styled.input`
  width: 315px;
  height: 50px;
  background: #ffffff;
  font-size: 30px;
  font-weight: 500;
  font-family: inherit;
  box-sizing: border-box;
  border: 3px solid #C02126;
  position: relative;
  text-align: center;
  outline: none;

  &::placeholder {
    color: #C02126;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const SubscribeButton = styled.div`
  background: #C02126;
  width: 315px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;

  &:hover {
    cursor: pointer;
  }
`;
