import React from 'react';
import styled from "styled-components";
import ArrowIconSrc from "../../../asset/icon__arrow-shop.svg";

const MainShopCollection = ({type, color, width, thumbnailWidth, height, name, price, img, onClick}) => {
    return (
        <MainGoodItemWrapper onClick={onClick} color={color} width={width} height={height}>
            <Image src={img} />
            <GoodContent thumbnailWidth={thumbnailWidth}>
                <Content>{name}</Content>
                <div style={{
                    padding: '27px 20px',
                }}><img src={ArrowIconSrc} width="440px" height="38px"/></div>
                {/*{price && <Content>${price}</Content>}*/}
                {/*<BuyNowButton onClick={onClick}>Learn more</BuyNowButton>*/}
            </GoodContent>
        </MainGoodItemWrapper>
    );
};

export default MainShopCollection;

MainShopCollection.defaultProps = {
    type: 'right',
    color: '#fff',
    width: "100%",
    thumbnailWidth: "40%",
    height: "80px",
}

const MainGoodItemWrapper = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({color}) => color && `background-color: ${color}`};
  
  &:hover {
    cursor: pointer;
  }
    //background-image: url(${({src}) => src && src});
  //background-size: cover;
`;

const Image = styled.img`
  width: 100%;
  src: url(${({src}) => src && src});
`;

const GoodContent = styled.div`
  // width: ${({thumbnailWidth}) => thumbnailWidth && `calc(100% - ${thumbnailWidth})`};
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Content = styled.div`
  width: 100%;
  color: #000;
  padding: 17px 0 17px 20px;
  box-sizing: border-box;
  font-size: 48px;
  font-weight: 500;
  line-height: 57.6px;
`;

const BuyNowButton = styled.div`
  width: 280px;
  height: 50px;
  background: #000;
  white-space: nowrap;
  color: #ffffff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;

  box-sizing: border-box;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }
`;
